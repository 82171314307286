import { combineReducers } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import authSlice from './Auth/authSlice';

const app_reducer = combineReducers({
    authSlice: authSlice
});

const root_reducer = (state: any, action: any) => {
    if (action.type === 'global/resetState') {
        return app_reducer(undefined, action);
    }

    return app_reducer(state, action);
}

export default configureStore({
    reducer: root_reducer
})