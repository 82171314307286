import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { unstable_usePrompt, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPlus, faSave } from "@fortawesome/free-solid-svg-icons";

import { performApiRequest } from "../../Common/api";
import FloatingSelect from "../Common/FloatingSelect";
import FloatingField from "../Common/FloatingField";
import BatchDetailRow, { RowInputs } from "./BatchDetailRow";
import { Product } from "../ProductGrid/ProductGridPage";
import { Batch } from "../BatchGrid/BatchGridPage";
import { useDispatch } from "react-redux";

interface Inputs {
    insurance_company: string,
    invoice_number: string,
    rows: RowInputs[]
}

interface ServerDefaults {
    max_evidence_number: string
}

export default function BatchDetailPage() {
    unstable_usePrompt({message: 'Opravdu se chcete vrátit zpět? Změny nebudou uloženy.', when: ({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname});

    /* Hooks */
    const { batch_id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [products, setProducts] = useState(null as Product[] | null);
    const [server_defaults, setServerDefaults] = useState(null as ServerDefaults | null);
    const [load_state, setLoadState] = useState({state: 'not_loaded'} as LoadState);
    const [save_state, setSaveState] = useState({state: 'not_saved'} as SaveState);
    const [defaults, setDefaults] = useState({});
    const { register, handleSubmit, watch, reset, formState: { errors, submitCount, touchedFields } } = useForm<Inputs>({
        defaultValues: defaults,
        mode: 'onBlur'
    });
    const [row_data, setRowData] = useState([] as RowInputs[]);

    /* Backend communication - product list */
    const getProducts = async () => {
        const response = await performApiRequest(dispatch, '/product', 'GET');
        const data = await response.json();
        setProducts(data as Product[]);
    }

    /* Backend communication - product list */
    const getServerDefaults = async () => {
        const response = await performApiRequest(dispatch, '/batch/defaults', 'GET');
        const data = await response.json();
        setServerDefaults(data as ServerDefaults);

        if (!batch_id && row_data.length == 0) {
            setRowData([{evidence_number: (parseInt(data.max_evidence_number) + 1).toString()} as RowInputs]);
        }
    }

    if (products === null) {
        getProducts();
    }

    if (server_defaults === null) {
        getServerDefaults();
    }

    /* Backend communication - batch, if ID is passed in URL */
    useEffect(() => {
        const getBatch = async (id: number) => {
            try {
                // retrieve data from backend
                const response = await performApiRequest(dispatch, '/batch/' + id, 'GET');
                const data = await response.json();

                // make sure rows are an array, if no rows were returned, set it to empty array
                data.rows = data.rows ? data.rows : [];

                // use the returned batch as defaults for header form
                setDefaults(data as Batch);

                // set to populate table rows with data from backend
                setRowData(data.rows ? data.rows : []);

                // reset the form with the data to force trigger updating
                reset(data);

                // update load state to display content
                setLoadState({state: 'loaded'});
            } catch(error) {
                // all errors are treated the same currently
                setLoadState({state: 'error', error_message: error as string});
            }
        }

        // parse the batch ID from URL, if set
        const _batch_id : number | null = batch_id ? parseInt(batch_id) : null;
        if (_batch_id && load_state.state === 'not_loaded') {
            // set the load state to trigger display of loading widget
            setLoadState({state: 'loading'});

            // trigger loading of the batch from backend
            getBatch(_batch_id);
        }
    }, []);

    /** Submit handler for the entire form */
    const onSubmit = (data: Inputs) => {
        // make sure we have the row data from the sub-forms properly set
        // on the main form data and that all are valid
        data.rows = row_data;
        let valid = true;
        for(let key in data.rows) {
            if (!data.rows[key].valid) {
                valid = false;
                break;
            }
        }
        
        if (valid) {
            // set date values to null if they are empty strings, otherwise backend would complain about format
            for (const row of data.rows) {
                row.voucher_approval_date = row.voucher_approval_date == '' ? null : row.voucher_approval_date;
                row.voucher_redeemal_date = row.voucher_redeemal_date == '' ? null : row.voucher_redeemal_date;
                row.handover_date = row.handover_date == '' ? null : row.handover_date;
            }


            // backend communication - submit the form data
            const saveBatch = async (data: Inputs) => {
                try {
                    if (batch_id) {
                        // replace existing batch
                        await performApiRequest(dispatch, '/batch/' + batch_id, 'POST', data);
                    } else {
                        // create new batch
                        await performApiRequest(dispatch, '/batch', 'PUT', data);
                    }

                    // after successfull save, we redirect back to the dashboard
                    navigate('/davky');
                } catch(error: any) {
                    // all errors are treaded equally
                    setSaveState({state: 'error', error_message: error.toString()})
                }
            }

            // trigger the saving to backend and update saving state to display loading widget
            saveBatch(data);
            setSaveState({state: 'saving'});
        }
    };

    // Callback to update local row data variable on all updated, to keep track of changes in individual rows
    const onRowUpdate = (index: number, data: RowInputs) => {
        row_data[index] = data;
    };

    // create new row, using the last evidence number as reference
    const onNewRow = () => setRowData([...row_data, {evidence_number: (parseInt(row_data[row_data.length - 1]?.evidence_number) + 1).toString()} as RowInputs]);

    // remove row and update state
    const onRowRemove = (index: number) => setRowData([...row_data.slice(0, index), ...row_data.slice(index + 1)]);

    // cloning a row by inserting a clone of the specified row immidiately after the specified index and updating state
    const onRowClone = (index: number) => {
        let new_row_data = row_data.map((value: RowInputs, index: number) => {
            return {...value};
        });

        // the cloned row copies only some properties, others are set with default or empty values
        const cloned_row = {...new_row_data[index]};
        cloned_row.full_price = undefined;
        cloned_row.insurance_price = undefined;
        cloned_row.client_price = undefined;
        cloned_row.note = '';

        new_row_data = [...new_row_data.slice(0, index + 1), cloned_row, ...new_row_data.slice(index + 1)];
        setRowData(new_row_data);
    };

    // if no products are loaded, batch is currently loading or the form is currently being saved, we don't display any content
    // and display only loading overlay div
    if (products === null || load_state.state === 'loading' || save_state.state === 'saving') {
        return <div className="loading-overlay">Načítání...<br /><br /><Spinner animation="border" variant="primary" /></div>;
    }

    // create individual rows for all the row data
    const rows = row_data.map((data: RowInputs, index: number) => {
        return <BatchDetailRow 
            products={products}
            submitCount={submitCount}
            key={index} 
            index={index} 
            data={data} 
            onUpdate={onRowUpdate} 
            onRemove={onRowRemove} 
            onClone={onRowClone}  />;
    });

    return <div>

        {load_state.state === 'error' || save_state.state === 'error' ? <Alert variant="danger">Při komunikaci se serverem došlo k chybě</Alert> : null}

        <Button variant="warning" className="float-end" onClick={() => {
            navigate('/davky');
        }}><FontAwesomeIcon icon={faArrowLeft} /> Zpět na seznam dávek</Button>

        <h3>Detail dávky</h3>

        <br />

        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col>
                    <FloatingSelect name="insurance_company" label="Pojišťovna" register={register} errors={errors} touchedFields={touchedFields}
                        options={[
                            {key: "111", value: "111 - VZP"},
                            {key: "201", value: "201 - VoZP"},
                            {key: "205", value: "205 - ČPZP"},
                            {key: "207", value: "207 - OZP"},
                            {key: "209", value: "209 - ZPŠ"},
                            {key: "211", value: "211 - ZPMV"},
                            {key: "213", value: "213 - RBP"}
                        ]} />
                </Col>
                <Col>
                    <FloatingField name="invoice_number" label="Číslo faktury" register={register} errors={errors} touchedFields={touchedFields} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table bordered={true}>
                        <thead>
                            <tr className="table-secondary">
                                <th rowSpan={2}>#</th>
                                <th>RČ klienta</th>
                                <th>Datum vystavení poukazu</th>
                                <th>IČZ předepisujícího lékaře</th>
                                <th>Úhradová skupina</th>
                                <th>Kód produktu</th>
                                <th>Celková cena</th>
                                <th>Úhrada pojišťovny</th>
                                <th>Úhrada klienta</th>
                                <th>Diagnóza klienta</th>
                                <th rowSpan={2} style={{width: '1px'}}></th>
                            </tr>
                            <tr className="table-secondary">
                                <th>Datum uplatnění poukazu</th>
                                <th>Odbornost předepisujícího</th>
                                <th>Evidenční číslo</th>
                                <th>Datum předání</th>
                                <th>Příjmení pojištěnce</th>
                                <th>ID zakázky</th>
                                <th colSpan={3}>Poznámka</th>
                            </tr>
                        </thead>
                        {rows}
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <Button variant="success" onClick={() => onNewRow()}><FontAwesomeIcon icon={faPlus} /></Button>
                    <Button variant="success" className="float-start" type="submit"><FontAwesomeIcon icon={faSave} /> Uložit</Button>
                </Col>
            </Row>
        </Form>
        <br />
    </div>
}