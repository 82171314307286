import FormFieldMessage from './FormFieldMessage';
import FormFieldInput from './FormFieldInput';
import { FloatingLabel } from 'react-bootstrap';

interface Props {
    name: string,
    label: string,
    register: any, 
    errors: object, 
    touchedFields: object, 
    type?: string, 
    placeholder?: string, 
    autoFocus?: boolean,
    validations?: object,
    onPaste?: any
}

const default_props = {
    type: 'text',
    placeholder: '',
    validations: {}
}

export default function FloatingField(props: Props) {
    props = {...default_props, ...props};

    const error_key = props.name as keyof typeof props.errors;
    const touched_key = props.name as keyof typeof props.touchedFields;

    return <FloatingLabel label={props.label} className="mb-3">
        <FormFieldInput 
            autoFocus={props.autoFocus}
            name={props.name} 
            register={props.register} 
            error={props.errors[error_key]} 
            touched_field={props.touchedFields[touched_key]}
            type={props.type}
            placeholder={props.placeholder}
            validations={props.validations}
            onPaste={props.onPaste} />
        <FormFieldMessage error={props.errors[error_key]} />
    </FloatingLabel>;
}