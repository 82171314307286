import { performApiRequest } from '../Common/api'

export const fetchProfile = async (dispatch: any) => {
    const response = await performApiRequest(dispatch, '/profile', 'GET');
    const body = await response.json();
    return body;
};

export const fetchToken  = async (user_name: string, password: string, dispatch: any) => {
    const response = await performApiRequest(dispatch, '/getToken', 'POST', {user_name: user_name, password: password});
    const body = await response.json();
    return body.api_token;
}