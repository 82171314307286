import { faTrash, faEnvelope, faFileExport, faPlus, faEdit, faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Form, Row, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { performApiRequest } from "../../Common/api";
import { parse, format } from "date-fns";
import FileDownload from 'js-file-download';
import { useDispatch } from "react-redux";

export type Batch = {
    id: number,
    number: string,
    insurance_company?: string,
    invoice_number?: string,
    rows: [],
    date_submitted: string,
    full_price?: number
}

export default function BatchGridPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [batches, setBatches] = useState([] as Batch[]);
    const [load_state, setLoadState] = useState({state: 'not_loaded'} as LoadState);
    const [delete_state, setDeleteState] = useState({state: 'not_saved'} as SaveState);
    const [sort, setSort] = useState({column: 'id', direction: 'asc'});
    const [filter, setFilter] = useState({
        insurance_company: null as null | string,
        date_submitted: null as null | string
    });

    const getBatches = async () => {
        const response = await performApiRequest(dispatch, '/batch', 'GET');
        const data = await response.json();
        setBatches(data as Batch[]);
        setLoadState({state: 'loaded'});
    }

    if (load_state.state === 'not_loaded') {
        setLoadState({state: 'loading'})
        getBatches();
    }

    const onDelete = (id: number) => {
        const deleteProduct = async (id: number) => {
            const response = await performApiRequest(dispatch, '/batch/' + id, 'DELETE');
            if (response.ok) {
                setBatches([]);
                setDeleteState({state: 'not_saved'});
                setLoadState({state: 'not_loaded'});
            }
        }

        deleteProduct(id);
        setDeleteState({state: 'saving'});
    };

    const onDownload = (batch: Batch) => {
        const downloadBatch = async (id: number) => {
            const response = await performApiRequest(dispatch, '/batch/' + id + '/download', 'GET');
            const blob = await response.blob();
            return blob;
        }

        downloadBatch(batch.id).then((content: any) => FileDownload(content, 'kdavka.' + batch.insurance_company));
    }

    const onCopy = (id: number) => {
        const copyBatch = async (id: number) => {
            const response = await performApiRequest(dispatch, '/batch/' + id + '/print-batch-info', 'GET');
            const text = await response.text();
            return text;
        }

        copyBatch(id).then((content: any) => navigator.clipboard.writeText(content));
    }

    const onSortChange = (column: string) => {
        const new_sort = {...sort};
        if (new_sort.column === column) {
            new_sort.direction = new_sort.direction === 'desc' ? 'asc' : 'desc';
        } else {
            new_sort.column = column;
            new_sort.direction = 'desc';
        }
        setSort(new_sort);
    }

    const onFilterChange = (column: 'insurance_company' | 'date_submitted', filter_value: string) => {
        filter[column] = filter_value;
        setFilter({...filter});
    }

    if (load_state.state === 'loading' || delete_state.state === 'saving') {
        return <div className="loading-overlay">Načítání...<br /><br /><Spinner animation="border" variant="primary" /></div>;
    }

    batches.sort((a: any, b: any) => {
        if (sort.column === 'id' || sort.column === 'full_price') {
            return a[sort.column] < b[sort.column] ? 1 : (a[sort.column] > b[sort.column] ? -1 : 0);
        }
        
        if (sort.column === 'rows') {
            return a.rows.length < b.rows.length ? 1 : (a.rows.length > b.rows.length ? -1 : 0);
        }

        return String(b[sort.column as any]).localeCompare(String(a[sort.column as any]));
    });
    
    let sorted_batcheds : Batch[];
    if (sort.direction === 'desc') {
        sorted_batcheds = batches.toReversed();
    } else {
        sorted_batcheds = batches;
    }
    
    const rows = [];
    for(const index in sorted_batcheds) {
        const batch = sorted_batcheds[index];
        const date_submitted_formatted = format(parse(batch.date_submitted, "yyyymmdd", new Date()), "yyyy/mm");

        if (filter.insurance_company != null && filter.insurance_company != "" && batch.insurance_company != filter.insurance_company) {
            continue;
        }

        if (filter.date_submitted !== null && !date_submitted_formatted.includes(filter.date_submitted)) {
            continue;
        }

        rows.push(
            <tr key={index}>
                <td>{batch.id}</td>
                <td>{batch.insurance_company}</td>
                <td>{date_submitted_formatted}</td>
                <td>{batch.number}</td>
                <td>{batch.rows ? batch.rows.length : 0}</td>
                <td>{batch.invoice_number}</td>
                <td>{batch.full_price ? Intl.NumberFormat('cs').format(batch.full_price) + ' Kč' : ''}</td>
                <td>
                    <Button variant="warning" size="sm" title="Upravit" onClick={() => navigate('/davka/' + batch.id)}><FontAwesomeIcon icon={faEdit} /></Button>
                    <Button variant="info" size="sm" title="Zkopírovat do schránky ve formátu pro email" onClick={() => {
                        onCopy(batch.id);
                    }}><FontAwesomeIcon icon={faEnvelope} /></Button>
                    <Button variant="success" size="sm" title="Stáhnout ve formátu pro pojišťovnu" onClick={() => {
                        onDownload(batch);
                    }}><FontAwesomeIcon icon={faFileExport} /></Button>
                    <Button variant="danger" size="sm" title="Smazat dávku" onClick={() => {
                        const confirmed = window.confirm("Opravdu chcete produkt smazat? Tato operace je nevratná!");
                        if (confirmed) {
                            onDelete(batch.id)
                        }
                    }}><FontAwesomeIcon icon={faTrash} /></Button>
                </td>
            </tr>
        );
    }

    const renderHeader = (column: string, name: string) => {
        const icon = column !== sort.column ? null : <FontAwesomeIcon icon={sort.direction === 'asc' ? faSortAsc : faSortDesc} />;

        return <th style={{cursor: 'pointer'}} onClick={() => onSortChange(column)}>
            {name} {icon}
        </th>;
    };

    return <div>
        <Button variant="success" className="float-end" onClick={() => navigate('/davka')}><FontAwesomeIcon icon={faPlus} /> Nová dávka</Button>

        <h3>Seznam dávek</h3>

        <br />

        <Row>
            <Col md={3} className="mb-3">
                <Form.Label>Pojišťovna</Form.Label>
                <Form.Select onChange={e => onFilterChange('insurance_company', e.target.value)}>
                    <option value="">Vše</option>
                    <option value="111">111 - VZP </option>
                    <option value="201">201 - VoZP</option>
                    <option value="205">205 - ČPZP</option>
                    <option value="207">207 - OZP</option>
                    <option value="209">209 - ZPŠ</option>
                    <option value="211">211 - ZPMV</option>
                    <option value="213">213 - RBP</option>
                </Form.Select>
            </Col>
            <Col md={3} className="mb-3">
                <Form.Label>Rok a měsíc podání</Form.Label>
                <Form.Control onChange={e => onFilterChange('date_submitted', e.target.value)} />
            </Col>
        </Row>

        <Table bordered={true}>
            <thead>
                <tr>
                    {renderHeader('id', 'ID')}
                    {renderHeader('insurance_company', 'Pojišťovna')}
                    {renderHeader('date_submitted', 'Rok a měsíc podání')}
                    {renderHeader('number', 'Číslo dávky')}
                    {renderHeader('rows', 'Počet poukazů')}
                    {renderHeader('invoice_number', 'Číslo faktury')}
                    {renderHeader('full_price', 'Celková částka')}
                    <th style={{width: "145px"}}>Akce</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={7} style={{textAlign: 'right'}}>Celkem: {batches.length}</th>
                </tr>
            </tfoot>
        </Table>
    </div>;
}