import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchProfile as apiFetchProfile, fetchToken as apiFetchToken } from './api'

export const fetchProfile = createAsyncThunk(
    'auth',
    async (_, { dispatch }) : Promise<object[]> => {
        const profile = await apiFetchProfile(dispatch);
        return profile;
    }
)


export const getToken = createAsyncThunk(
    'token',
    async (payload: {username: string, password: string}, { dispatch }) : Promise<string> => {
        const token = await apiFetchToken(payload.username, payload.password, dispatch);
        localStorage.token = token;
        return token;
    }
)

const token = localStorage.getItem('token');

const authSlice = createSlice({
    name: 'authSlice',
    initialState: {
        token: token as undefined | string,
        profile: undefined as Profile | undefined,
        load_states: {
            profile: {state: 'not_loaded'} as LoadState,
            token: {state: token ? 'loaded' : 'not_loaded'} as LoadState
        }
    },
    reducers: {
        logout(state) {
            state.token = undefined;
            localStorage.token = undefined;
            state.load_states.token.state = 'not_loaded';
            state.profile = undefined;
            state.load_states.profile.state = 'not_loaded';
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProfile.fulfilled, (state, action) => {
            state.profile = action.payload as any;
            state.load_states.profile.state = 'loaded';
        });
        builder.addCase(fetchProfile.pending, (state) => {
            state.profile = undefined;
            state.load_states.profile.state = 'loading';
        });
        builder.addCase(fetchProfile.rejected, (state, action) => {
            state.profile = undefined;
            state.load_states.profile.state = 'error';
            state.load_states.profile.error_message = action.payload as string;
        });
        builder.addCase(getToken.fulfilled, (state, action) => {
            state.token = action.payload;
            state.load_states.token.state = 'loaded';
        });
        builder.addCase(getToken.pending, (state) => {
            state.token = undefined;
            state.load_states.token.state = 'loading';
        });
        builder.addCase(getToken.rejected, (state, action) => {
            state.token = undefined;
            state.load_states.token.state = 'error';
            state.load_states.token.error_message = action.payload as string;
        });
    }
})

export const { logout } = authSlice.actions
export default authSlice.reducer