import { Form } from 'react-bootstrap';

interface Props {
    name: string, 
    register: any, 
    error: object, 
    readOnly?: boolean,
    defaultValue?: string | number,
    touched_field: object,
    options: Option[],
    validations?: object
}

export interface Option {
    key: string | number,
    value: string | number,
    group?: string | undefined
}

const default_props = {
    type: 'text',
    placeholder: '',
    validations: {}
}

const convertOptionsToElements = (options: Option[]) => options.map((value) => <option value={value.key} key={value.key}>{value.value}</option>);

export default function FormSelectInput(props: Props) {
    props = {...default_props, ...props};

    let last_group;
    let current_group : Option[] = [];
    let options = [];
    for (const _option of props.options) {
        if (_option.group !== last_group) {
            options.push(<optgroup key={_option.group} label={last_group}>{convertOptionsToElements(current_group)}</optgroup>);
            current_group = [];
        }

        current_group.push(_option);
        last_group = _option.group;
    }

    if (options.length === 0) {
        options = convertOptionsToElements(current_group);
    } else {
        options.push(<optgroup key="last_group" label={last_group}>{convertOptionsToElements(current_group)}</optgroup>);
    }

    return <Form.Select 
        {...props.register(props.name, props.validations)} 
        isInvalid={!!props.error} 
        defaultValue={props.defaultValue}
        isValid={!props.error && props.touched_field}
        disabled={props.readOnly}>
        {options}
    </Form.Select>
}