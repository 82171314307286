/**
 * Common API functions and utilities
 */
import { logout } from '../Auth/authSlice';
import config from '../config.json';

/**
 * Allows pausing of async functions for given duration - used to simulate longer load times - mainly for testing purposes
 */
function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * performs an API request, tailored specifically for the backend of this application
 */
export const performApiRequest = async (dispatch: any, endpoint: string, method: string, payload?: object, payload_type: string = 'json') => {
    const token = localStorage.token;
    const options = {
        method: method,
        headers: {
            "X-Api-Key": token
        }
    } as any;

    

    if (payload) {
        if (payload_type === 'json') {
            options.body = JSON.stringify(payload);
        } else {
            options.body = payload;
        }
    }

    // used for testing, should not have effect in production (governed by config)
    await sleep(config.API_DELAY_MS);

    const response = await fetch(config.API_URL + endpoint, options);

    if (response.status === 401) {
        dispatch(logout());
    }

    if (!response.ok) {
        throw "Error";
    }
    
    return response;
}