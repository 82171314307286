import './App.scss';

import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Nav, Navbar as BsNavbar } from 'react-bootstrap';
import { Route, Outlet, NavLink, ScrollRestoration, createRoutesFromElements, RouterProvider, createBrowserRouter, Navigate } from 'react-router-dom';

import AuthWidget from './components/Common/AuthWidget';
import BatchGridPage from './components/BatchGrid/BatchGridPage';
import BatchDetailPage from './components/BatchDetail/BatchDetailPage';
import ProductGridPage from './components/ProductGrid/ProductGridPage';
import ProductDetailPage from './components/ProductDetail/ProductDetailPage';
import { logout } from './Auth/authSlice';

export default function App() {
    const profile = useSelector((state: any) => state.authSlice.profile);

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<Layout profile={profile} />}>
                <Route path="davky" element={<BatchGridPage />} />
                <Route path="davka/:batch_id?" element={<BatchDetailPage />} />
                <Route path="produkty" element={<ProductGridPage />} />
                <Route path="produkt/:product_id?" element={<ProductDetailPage />} />
                <Route path="/" element={<Navigate to="davky" />} />
            </Route>
        )
    );

    return (
        <AuthWidget>
            <RouterProvider router={router} />
        </AuthWidget>
    );
}

function Layout(props: {profile?: Profile}) {

    const dispatch = useDispatch();

    const logOut = () => {
        dispatch(logout());
    };

    return <div>
        <BsNavbar bg="secondary" expand="lg" variant="dark">
            <Container fluid>
                <BsNavbar.Brand style={{cursor: 'pointer'}}>
                <img 
                    src="logo.png"
                    alt="Ultina" 
                    style={{height: '25px', marginRight: '20px'}} />
                    Generátor dávek
                </BsNavbar.Brand>
                <BsNavbar.Toggle />
                <BsNavbar.Collapse id="main-navbar">
                    <Nav className="me-auto" key="1">
                        <Nav.Link key="batches" as={NavLink} to="/davky">Dávky</Nav.Link>
                        <Nav.Link key="products" as={NavLink} to="/produkty">Produkty</Nav.Link>
                    </Nav>
                    <Nav className="d-flex" key="2">
                        <BsNavbar.Text>Přihlášen jako <strong>{props.profile?.display_name}</strong> | <a href="#" onClick={logOut}>Odhlásit</a></BsNavbar.Text>
                    </Nav>
                </BsNavbar.Collapse>
            </Container>
        </BsNavbar>
        <Container fluid>
            <Row>
                <Col>
                    <br />
                    <Outlet />
                </Col>
            </Row>
        </Container>

        <ScrollRestoration />
    </div>;
}