import FormFieldMessage from './FormFieldMessage';
import FormSelectInput, { Option } from './FormSelectInput';
import { FloatingLabel } from 'react-bootstrap';

interface Props {
    name: string,
    label: string,
    register: any, 
    errors: object, 
    readOnly?: boolean,
    defaultValue?: string | number,
    touchedFields: object,
    options: Option[],
    selected_option?: number | undefined,
    validations?: object
}

const default_props = {
    validations: {}
}

export default function FloatingSelect(props: Props) {
    props = {...default_props, ...props};

    const error_key = props.name as keyof typeof props.errors;
    const touched_key = props.name as keyof typeof props.touchedFields;

    return <FloatingLabel label={props.label} className="mb-3">
        <FormSelectInput
            name={props.name} 
            defaultValue={props.defaultValue}
            register={props.register}
            readOnly={props.readOnly}
            error={props.errors[error_key]} 
            touched_field={props.touchedFields[touched_key]}
            options={props.options}
            validations={props.validations} />
        <FormFieldMessage error={props.errors[error_key]} />
    </FloatingLabel>;
}