import { Form } from 'react-bootstrap';

interface Props {
    name: string, 
    register: any, 
    error: object, 
    touched_field: object, 
    type?: string, 
    placeholder?: string, 
    validations?: object,
    autoFocus?: boolean,
    onPaste?: any
}

const default_props = {
    type: 'text',
    placeholder: '',
    validations: {}
}

export default function FormFieldInput(props: Props) {
    props = {...default_props, ...props};

    return <Form.Control 
        type={props.type} 
        onPaste={props.onPaste}
        autoFocus={props.autoFocus}
        placeholder={props.placeholder} 
        {...props.register(props.name, props.validations)} 
        isInvalid={!!props.error} 
        isValid={!props.error && props.touched_field} />
}