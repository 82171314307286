import { useDispatch, useSelector } from "react-redux";
import { fetchProfile, getToken } from "../../Auth/authSlice";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import config from "../../config.json";
import { useForm } from "react-hook-form";

interface Props {
    children: any
}

interface Inputs {
    username: string,
    password: string
}

export default function AuthWidget(props: Props) {
    const profile_load_state = useSelector((state: any) => state.authSlice.load_states.profile);
    const token_load_state = useSelector((state: any) => state.authSlice.load_states.token);
    const dispatch = useDispatch<any>();
    const { register, handleSubmit } = useForm<Inputs>();

    const onLoginSubmit = (data: Inputs) => {
        dispatch(getToken({username: data.username, password: data.password}));
    };

    const token_loading = token_load_state.state === 'loading';
    if (token_load_state.state != 'loaded') {
        return <div className="mx-auto mt-5" style={{width: '200px'}}>
            <Form onSubmit={handleSubmit(onLoginSubmit)}>
                {token_load_state.state === 'error' ? <Alert variant="danger">Nastala chyba při přihlašování</Alert> : ''}
                <Form.Group className="mb-3">
                    <Form.Label>Uživatel</Form.Label>
                    <Form.Control disabled={token_loading} {...register('username')} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Heslo</Form.Label>
                    <Form.Control type="password" disabled={token_loading} {...register('password')} />
                </Form.Group>
                <input type="submit" className="btn btn-success" disabled={token_loading} value={token_loading ? "Probíhá přihlašování" : "Přihlásit"} />
            </Form>
        </div>;
    }

    if (profile_load_state.state === 'not_loaded') {
        dispatch(fetchProfile());
        return <></>;
    }

    if (profile_load_state.state === 'error') {
        return <div>
        </div>;
    }

    if (profile_load_state.state === 'loading') {
        return <div className="text-center h2" style={{height: '100vh', paddingTop: 'calc(50vh - 16px)'}}>Probíhá autentizace<br /><Spinner animation="grow" variant="primary" /></div>;
    }

    return <>{props.children}</>;
}