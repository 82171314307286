import { useForm } from "react-hook-form";
import React, { useEffect, useRef, useState, FocusEvent } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { validateBirthNumber, validateManualDate } from "../Common/validations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import { format, parse } from "date-fns";
import { Product } from "../ProductGrid/ProductGridPage";

interface Props {
    index: number,
    data: RowInputs,
    submitCount: number,
    products: Product[],
    onUpdate: (index: number, data: RowInputs) => void,
    onRemove: (index: number) => void,
    onClone: (index: number) => void
}

export interface RowInputs {
    birth_number: string,
    voucher_approval_date: string | null,
    doctor_icz: string,
    payment_group: 1 | 2,
    product_id: string,
    full_price?: number,
    insurance_price?: number,
    client_price?: number,
    client_diagnosis: string,
    voucher_redeemal_date: string | null,
    doctor_expertise: string,
    doctor_expertise_custom: string,
    evidence_number: string,
    handover_date: string | null,
    surname: string,
    contract_id: string,
    note: string,
    valid: boolean
}

export default function BatchDetailRow(props: Props, ref: any) 
{
    const accessories_product_ids = props.products.filter((e: Product) => e.type === 'Accessory').map((e) => e.id);

    const { register, reset, watch, setValue, trigger, formState: { errors, touchedFields, isValid, isSubmitted, dirtyFields } } = useForm<RowInputs>({
        defaultValues: props.data,
        mode: 'onBlur'
    });

    const first_update = useRef(true);
    useEffect(() => {
        if (first_update.current) {
            first_update.current = false;
            return;
        }

        trigger();
    }, [props.submitCount]);

    const row_data = watch();

    useEffect(() => reset(props.data), [props.data]);
    useEffect(() => {
        props.onUpdate(props.index, {...row_data, valid: isValid });
    }, [row_data]);

    const voucher_approval_date = watch('voucher_approval_date');
    const voucher_redeemal_date = watch('voucher_redeemal_date');
    const handover_date = watch('handover_date');

    useEffect(() => {
        if (!voucher_redeemal_date &&
            dirtyFields.voucher_approval_date && 
            !dirtyFields.voucher_redeemal_date && 
            validateManualDate(String(voucher_approval_date)) === true) {
            const date = parse(String(voucher_approval_date), 'ddMMyyyy', new Date);
            if (!isNaN(date as any)) {
                date.setDate(date.getDate() + 28);
                setValue('voucher_redeemal_date', format(date, 'ddMMyyyy'));
            }
        }
    }, [voucher_approval_date]);

    useEffect(() => {
        if (!handover_date && !dirtyFields.handover_date) {
            setValue('handover_date', voucher_redeemal_date);
        }
    }, [voucher_redeemal_date]);

    const birth_number = watch('birth_number');
    const payment_group = watch('payment_group');
    const product_id = watch('product_id');
    const client_price = watch('client_price');
    const insurance_price = watch('insurance_price');
    const full_price = watch('full_price');
    const doctor_expertise = watch('doctor_expertise');
    const evidence_number = watch('evidence_number');

    const [doctor_expertise_custom, setDoctorExpertiseCustom] = useState(props.data.doctor_expertise === "0");
    useEffect(() => {
        if (doctor_expertise === "0") {
            setDoctorExpertiseCustom(true);
        } else {
            setDoctorExpertiseCustom(false);
        }
    }, [doctor_expertise]);

    const onDoctorExpertiseCustomCancel = () => setValue('doctor_expertise', '201');

    useEffect(() => {
        const product = props.products.find((product) => product.id === parseInt(product_id));

        if (payment_group == 1 && product?.type === "Wheelchair") {
            setValue('insurance_price', product.price_insurance);
            
            if (!full_price && client_price && insurance_price) {
                setValue('full_price', client_price + insurance_price);
            }

            if (product.price_insurance && full_price) {
                setValue('client_price', +((full_price - product.price_insurance).toFixed(2)));
            }
        }

        if (payment_group == 1 && product?.type === "Accessory") {
            if (client_price && insurance_price) {
                setValue('full_price', client_price + insurance_price)
            }

            setValue('client_price', product.price_client);
            setValue('insurance_price', product.price_insurance);
        }

        if (full_price && payment_group == 2 && product?.type === "Wheelchair") {
            setValue('client_price', Math.round(full_price * 0.1));
            setValue('insurance_price', Math.round(full_price * 0.9));
        }

        if (payment_group == 2 && product?.type === "Accessory") {
            setValue('full_price', undefined);
            setValue('client_price', undefined);
            setValue('insurance_price', undefined);
        }
    }, [client_price, insurance_price, payment_group, product_id, full_price]);


    const onRowFieldFocus = (e: FocusEvent<HTMLInputElement>) => e.currentTarget?.closest('tbody')?.classList.add('focused');
    const onRowFieldBlur = (e: FocusEvent<HTMLInputElement>) => e.currentTarget?.closest('tbody')?.classList.remove('focused');
    const onRowSelectFocus = (e: FocusEvent<HTMLSelectElement>) => e.currentTarget?.closest('tbody')?.classList.add('focused');
    const onRowSelectBlur = (e: FocusEvent<HTMLSelectElement>) => e.currentTarget?.closest('tbody')?.classList.remove('focused');

    const onBirthNumberCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
        const length = birth_number.length;
        let parts = birth_number.match(/(..?)/g);

        if (!parts || parts.length < 3 || length < 9 || length > 10) {
            return;
        }

        parts = parts.slice(0, 3);
        let year = parseInt(parts[0]);

        if (year < 54 && length == 10) {
            year = year + 2000;
        }

        let month = parseInt(parts[1]);
        const day = parseInt(parts[2]);
        month = month > 12 ? (month - 50) : month;
        const date = new Date(year, month - 1, day);

        if (isNaN(date)) {
            return;
        }

        navigator.clipboard.writeText(format(date, 'dd.MM.yyyy'));
    };


    const onEvidenceNumberCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
        navigator.clipboard.writeText(evidence_number);
    }


    return <tbody key={props.index} className="with-tiny-labels">
        <tr className="table-light" style={{borderTop: '2px solid black'}}>
            <td rowSpan={2}>{props.index + 1}</td>
            <td>
                <div className="tiny-label">Rodné číslo</div>
                <InputGroup>
                    <Form.Control onFocus={onRowFieldFocus} {...register('birth_number', {
                        validate: validateBirthNumber,
                        onBlur: onRowFieldBlur
                    })} isInvalid={!!(errors?.birth_number)} />
                    <Button variant="outline-secondary" style={{width: '25px', paddingLeft: '5px'}} onClick={onBirthNumberCopy}>
                        <FontAwesomeIcon icon={faCopy} />
                    </Button>
                </InputGroup>
                <Form.Control.Feedback type="invalid">{errors?.birth_number?.message}</Form.Control.Feedback>
            </td>


            <td>
                <div className="tiny-label">Datum vystavení poukazu</div>
                <Form.Control onFocus={onRowFieldFocus} {...register('voucher_approval_date', {
                    validate: validateManualDate,
                    onBlur: onRowFieldBlur
                })} isInvalid={!!(errors?.voucher_approval_date)} />
                <Form.Control.Feedback type="invalid">{errors?.voucher_approval_date?.message}</Form.Control.Feedback>
            </td>


            <td>
                <div className="tiny-label">IČZ předepisujícího lékaře</div>
                <Form.Control onFocus={onRowFieldFocus} maxLength={200}  {...register('doctor_icz', {
                    maxLength: 200,
                    onBlur: onRowFieldBlur
                })} isInvalid={!!(errors?.doctor_icz)} />
                <Form.Control.Feedback type="invalid">{errors?.doctor_icz?.message}</Form.Control.Feedback>
            </td>


            <td>
                <div className="tiny-label">Úhradová skupina</div>
                <Form.Select onFocus={onRowSelectFocus} isValid={isSubmitted || touchedFields?.payment_group} {...register('payment_group', {
                    onBlur: onRowFieldBlur
                })}>
                    <option value="1">07</option>
                    <option value="2">Servis</option>
                </Form.Select>
            </td>


            <td>
                <div className="tiny-label">Kód produktu</div>
                <Form.Select onFocus={onRowSelectFocus} isValid={isSubmitted || touchedFields?.product_id} {...register('product_id', {
                    onBlur: onRowSelectBlur
                })}>
                    {props.products.map((product, index) => <option key={index} value={product.id}>{product.name}</option>)}
                </Form.Select>
            </td>


            <td>
                <div className="tiny-label">Celková cena</div>
                <Form.Control 
                    disabled={accessories_product_ids.includes(parseInt(product_id))} 
                    type="number" step="0.01"
                    {...register('full_price', {onBlur: onRowFieldBlur})} /></td>


            <td>
                <div className="tiny-label">Úhrada pojišťovny</div>
                <Form.Control 
                onFocus={onRowFieldFocus} 
                disabled={true} 
                type="number" step="0.01"
                {...register('insurance_price', {onBlur: onRowFieldBlur})} /></td>


            <td>
                <div className="tiny-label">Úhrada klienta</div>
                <Form.Control 
                onFocus={onRowFieldFocus} 
                disabled={true} 
                type="number"  step="0.01"
                {...register('client_price', {onBlur: onRowFieldBlur})} /></td>


            <td>
                <div className="tiny-label">Diagnóza klienta</div>
                <Form.Control onFocus={onRowFieldFocus} maxLength={200} {...register('client_diagnosis', {
                    maxLength: 200,
                    onBlur: onRowFieldBlur
                })} isInvalid={!!(errors?.client_diagnosis)} />
                <Form.Control.Feedback type="invalid">{errors?.client_diagnosis?.message}</Form.Control.Feedback>
            </td>


            <td rowSpan={2}>
                <Button variant="secondary" size="sm" onClick={() => props.onClone(props.index)} title="Klonovat řádek"><FontAwesomeIcon icon={faCopy} /></Button>
                <Button variant="danger" size="sm" onClick={() => props.onRemove(props.index)} title="Odstranit řádek"><FontAwesomeIcon icon={faTrash} /></Button>
            </td>
        </tr>

        <tr className="table-light">
            <td>
                <div className="tiny-label">Datum uplatnění poukazu</div>
                <Form.Control onFocus={onRowFieldFocus} {...register('voucher_redeemal_date', {
                    validate: validateManualDate,
                    onBlur: onRowFieldBlur
                })} isInvalid={!!(errors?.voucher_redeemal_date)} />
                <Form.Control.Feedback type="invalid">{errors?.voucher_redeemal_date?.message}</Form.Control.Feedback>
            </td>


            <td>
                <div className="tiny-label">Odbornost předepisujícího</div>
                <Form.Select onFocus={onRowSelectFocus} style={{display: doctor_expertise_custom ? 'none' : 'initial'}} 
                    isValid={isSubmitted || touchedFields?.doctor_expertise} {...register('doctor_expertise', {onBlur: onRowSelectBlur})}>
                    <option value="201">Rehabilitace</option>
                    <option value="209">Neurologie</option>
                    <option value="409">Dětská neurologie</option>
                    <option value="0">Vlastní</option>
                </Form.Select>
                <div className="doctor-expertise-custom-field" style={{display: !doctor_expertise_custom ? 'none' : 'block'}}>
                    <Form.Control {...register('doctor_expertise_custom')} isInvalid={!!(errors?.doctor_expertise_custom)} />
                    <button type="button" onClick={() => onDoctorExpertiseCustomCancel()}><FontAwesomeIcon icon={faCancel} /></button>
                </div>
            </td>


            <td>
                <div className="tiny-label">Evidenční číslo</div>
                <InputGroup>
                    <Form.Control onFocus={onRowFieldFocus} maxLength={20} {...register('evidence_number', {
                        maxLength: 20,
                        validate: value => value == '' || String(value).match(/^(0|[1-9]\d*)([\.,]\d+)?$/) ? true : "Je potřeba vyplnit platné číslo",
                        onBlur: onRowFieldBlur
                    })} isInvalid={!!(errors?.evidence_number)} />
                    <Button variant="outline-secondary" style={{width: '25px', paddingLeft: '5px'}} onClick={onEvidenceNumberCopy}>
                        <FontAwesomeIcon icon={faCopy} />
                    </Button>
                </InputGroup>
                <Form.Control.Feedback type="invalid">{errors?.evidence_number?.message}</Form.Control.Feedback>
            </td>


            <td>
                <div className="tiny-label">Datum předání</div>
                <Form.Control onFocus={onRowFieldFocus} {...register('handover_date', {
                    validate: validateManualDate,
                    onBlur: onRowFieldBlur
                })} isInvalid={!!(errors?.handover_date)} />
                <Form.Control.Feedback type="invalid">{errors?.handover_date?.message}</Form.Control.Feedback>
            </td>


            <td>
                <div className="tiny-label">Příjmení pojištěnce</div>
                <Form.Control onFocus={onRowFieldFocus} maxLength={200} {...register('surname', {
                    maxLength: 200,
                    onBlur: onRowFieldBlur
                })} isInvalid={!!(errors?.surname)} />
                <Form.Control.Feedback type="invalid">{errors?.surname?.message}</Form.Control.Feedback>
            </td>


            <td>
                <div className="tiny-label">ID zakázky</div>
                <Form.Control onFocus={onRowFieldFocus} maxLength={200} {...register('contract_id', {
                    maxLength: 200,
                    onBlur: onRowFieldBlur
                })} isInvalid={!!(errors?.surname)} />
                <Form.Control.Feedback type="invalid">{errors?.surname?.message}</Form.Control.Feedback>
            </td>


            <td colSpan={3}>
                <div className="tiny-label">Poznámka</div>
                <Form.Control onFocus={onRowFieldFocus} style={{display: 'inline-block', width: 'calc(100% - 40px)'}} {...register('note', {
                    maxLength: 200,
                    onBlur: onRowFieldBlur
                })} isInvalid={!!(errors?.note)} />
                <Form.Control.Feedback type="invalid">{errors?.note?.message}</Form.Control.Feedback>
            </td>
        </tr>
    </tbody>
}