import { parse, parseISO } from "date-fns";
import isValid from "date-fns/isValid";

export const validateIdNumber = (id_number: string | number) : true | string => {
    if (id_number === '') {
        return true;
    }

    id_number = id_number.toString();
    let input = id_number.replace(/\s/g, '');
    const regex = /^\d(\d?){7}$/;

    if (!regex.test(input)) {
        return "IČ není ve správném formátu (pouze číslice, max 8)";
    }

    input = input.padStart(8, '0');

    let a = 0;
    for (let i = 0; i < 7; i++) {
        a += parseInt(input.charAt(i)) * (8 - i);
    }

    a = a % 11;
    let c = a === 0 ? 1 : (a === 1 ? 0 : 11 - a);

    if (c !== parseInt(input.charAt(7))) {
        return "IČ není platné";
    }

    return true;
}

export const validateVatNumber = (vat_number: string | number) : true | string => {
    if (vat_number === '') {
        return true;
    }

    const regex = /^\s*CZ(\d?){2}\d{8}\s*$/;

    if (!regex.test(vat_number.toString())) {
        return "DIČ není ve správném formátu (např.: CZ12345678)";
    }
    
    return true;
}

export const validateBirthNumber = (birth_number: string) : true | string => {
    if (birth_number === '') {
        return true;
    }

    const regex = /^\s*(\d\d)(\d\d)(\d\d)[ /]*(\d\d\d)(\d?)\s*$/;
    if (!regex.test(birth_number)) {
        return "Rodné číslo není ve správném formátu (např.: XXXXXX/XXXX)";
    }

    const matches = birth_number.match(regex) as string[];
    let [_, year_str, month_str, day_str, ext_str] = matches;
    const matches_num = matches.map((match: string) => parseInt(match));
    let [__, year, month, ___, ____, c] = matches_num;
    
    if (c === undefined || Number.isNaN(c)) {
        year += year < 54 ? 1900 : 1800;
    } else {
        let mod = parseInt(year_str + month_str + day_str + ext_str) % 11;
        mod = mod === 10 ? 0 : mod;

        if (mod !== c) {
            return "Rodné číslo není platné";
        }

        year += year < 54 ? 2000 : 1900;
    }

    if (month > 70 && year > 2003) {
        month -= 70;
    } else if (month > 50) {
        month -= 50;
    } else if (month > 20 && year > 2003) {
        month -= 20;
    }

    const iso_date = year.toString() + "-" + month.toString().padStart(2, '0') + "-" + day_str;
    if (!isValid(parseISO(iso_date))) {
        return "Datumová část rodného čísla není platný datum";
    }

    return true;
}

export const validatePhoneNumber = (phone_number : string) : true | string => {
    if (phone_number === '') {
        return true;
    }

    return phone_number.match(/^\s*(((\+)|(00))\d\d\d?)?(\s*\d\s*){9}\s*$/) ? true : "Telefonní číslo není platné";
}


export const validateManualDate = (date_string: string | null) : true | string => {
    if (date_string === '' || date_string === null || date_string === undefined) {
        return true;
    }

    if (date_string.length != 8) {
        return "Datum není platný";
    }

    return isNaN(parse(date_string, 'ddMMyyyy', new Date()) as any) ? "Datum není platný" : true;
}