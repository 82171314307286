import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import FloatingField from "../Common/FloatingField";
import { useForm } from "react-hook-form";
import FloatingSelect from "../Common/FloatingSelect";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSave } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { performApiRequest } from "../../Common/api";
import { Product } from "../ProductGrid/ProductGridPage";
import { useDispatch } from "react-redux";

interface Inputs {
    name: string,
    code: string,
    price_insurance: number,
    price_client: number,
    type: 1 | 2
}

export default function ProductDetailPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { product_id } = useParams();
    const [load_state, setLoadState] = useState({state: 'not_loaded'} as LoadState);
    const [save_state, setSaveState] = useState({state: 'not_saved'} as SaveState);
    const [defaults, setDefaults] = useState({});
    
    const {
        register,
        handleSubmit,
        reset,
        formState: {
            errors,
            touchedFields
        }
    } = useForm<Inputs>({
        defaultValues: defaults,
        mode: 'onBlur'
    });

    useEffect(() => {
        const getProduct = async (id: number) => {
            try {
                const response = await performApiRequest(dispatch, '/product/' + id, 'GET');
                const data = await response.json();
                setDefaults(data as Product);
                reset(data);
                setLoadState({state: 'loaded'});
            } catch(error) {
                setLoadState({state: 'error', error_message: error as string});
            }
        }

        const _product_id : number | null = product_id ? parseInt(product_id) : null;
        if (_product_id && load_state.state === 'not_loaded') {
            setLoadState({state: 'loading'})
            getProduct(_product_id);
        }
    }, []);

    const onSubmit = (data: Inputs) => {
        const saveProduct = async (data: Inputs) => {
            try {
                if (product_id) {
                    await performApiRequest(dispatch, '/product/' + product_id, 'POST', data);
                } else {
                    await performApiRequest(dispatch, '/product', 'PUT', data);
                }
                navigate('/produkty');
            } catch(error: any) {
                setSaveState({state: 'error', error_message: error.toString()})
            }
        }

        saveProduct(data);
        setSaveState({state: 'saving'});
    };

    if (load_state.state === 'loading' || save_state.state === 'saving') {
        return <div className="loading-overlay">Načítání...<br /><br /><Spinner animation="border" variant="primary" /></div>;
    }

    const error_message = save_state.state === 'error' ? <Alert>{save_state.error_message}</Alert> : null;

    return <div>
        {error_message}

        <Button variant="warning" className="float-end" onClick={() => navigate('/produkty')}><FontAwesomeIcon icon={faArrowLeft} /> Zpět na seznam produktů</Button>

        <h3>Detail produktu</h3>

        <br />

        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col>
                    <FloatingField name="name" label="Název produktu" register={register} errors={errors} touchedFields={touchedFields} />
                </Col>
                <Col>
                    <FloatingField name="code" label="Předepisovací kód" register={register} errors={errors} touchedFields={touchedFields} />
                </Col>
                <Col>
                    <FloatingSelect name="type" label="Typ produktu" register={register} errors={errors} touchedFields={touchedFields}
                        options={[{key: 'Wheelchair', value: "Vozík"}, {key: 'Accessory', value: "Příslušenství"}]} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FloatingField name="price_insurance" label="Úhrada pojišťovna" register={register} errors={errors} touchedFields={touchedFields} />
                </Col>
                <Col>
                    <FloatingField name="price_client" label="Úhrada klient" register={register} errors={errors} touchedFields={touchedFields} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button type="submit" variant="success"><FontAwesomeIcon icon={faSave} /> Uložit</Button>
                </Col>
            </Row>
        </Form>
    </div>;
}