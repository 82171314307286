import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Spinner, Table } from "react-bootstrap";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import config from "../../config.json"
import { performApiRequest } from "../../Common/api";
import { useDispatch } from "react-redux";

export type Product = {
    id: any,
    name: string,
    code: string,
    type: string,
    price_client?: number,
    price_insurance?: number
}

export default function ProductGridPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [products, setProducts] = useState([] as Product[]);
    const [load_state, setLoadState] = useState({state: 'not_loaded'} as LoadState);
    const [delete_state, setDeleteState] = useState({state: 'not_saved'} as SaveState);

    const getProducts = async () => {
        const response = await performApiRequest(dispatch, '/product', 'GET');
        const data = await response.json();
        setProducts(data as Product[]);
        setLoadState({state: 'loaded'});
    }

    if (load_state.state === 'not_loaded') {
        getProducts();
    }

    const onDelete = (id: number) => {
        const deleteProduct = async (id: number) => {
            const response = await performApiRequest(dispatch, '/product/' + id, 'DELETE');
            if (response.ok) {
                setProducts([]);
                setDeleteState({state: 'not_saved'});
                setLoadState({state: 'not_loaded'});
            }
        }

        deleteProduct(id);
        setDeleteState({state: 'saving'});
    };

    if (load_state.state === 'loading' || delete_state.state === 'saving') {
        return <div className="loading-overlay">Načítání...<br /><br /><Spinner animation="border" variant="primary" /></div>;
    }

    const rows = [];
    for(const index in products) {
        const product = products[index];

        rows.push(
            <tr key={index}>
                <td>{product.name}</td>
                <td>{product.code}</td>
                <td>{product.price_insurance ? (Intl.NumberFormat('cs').format(product.price_insurance) + ' Kč') : ''}</td>
                <td>{product.price_client ? (Intl.NumberFormat('cs').format(product.price_client) + ' Kč') : ''}</td>
                <td>{product.type === 'Wheelchair' ? 'Vozík' : 'Příslušenství'}</td>
                <td>
                    <Button variant="warning" size="sm" title="Upravit" onClick={() => navigate('/produkt/' + product.id)}><FontAwesomeIcon icon={faEdit} /></Button>
                    <Button variant="danger" size="sm" onClick={() => {
                        const confirmed = window.confirm("Opravdu chcete produkt smazat? Tato operace je nevratná!");
                        if (confirmed) {
                            onDelete(product.id)
                        }
                    }}><FontAwesomeIcon icon={faTrash} /></Button>
                </td>
            </tr>
        );
    }

    return <div>
        <Button variant="success" className="float-end" onClick={() => navigate('/produkt')}><FontAwesomeIcon icon={faPlus} /> Nový produkt</Button>

        <h3>Seznam produktů</h3>

        <br />

        <Table bordered={true}>
            <thead>
                <tr>
                    <th>Název</th>
                    <th>Předepisovací kód</th>
                    <th>Úhrada pojišťovny</th>
                    <th>Úhrada klienta</th>
                    <th>Typ produktu</th>
                    <th style={{width: "80px"}}>Akce</th>
                </tr>
            </thead>
            <tbody>
                {rows}
            </tbody>
            <tfoot>
                <tr>
                    <th colSpan={6} style={{textAlign: 'right'}}>Celkem: 10</th>
                </tr>
            </tfoot>
        </Table>
    </div>;
}